<template>
  <div>
    <b-row
      class="pt-1 border-top-dark"
    >
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>DOMAIN LAYANAN</h5>
        <p>{{ dataDoc.domain_layanan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>AREA LAYANAN</h5>
        <p>{{ dataDoc.area_layanan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>KATEGORI LAYANAN</h5>
        <p>{{ dataDoc.kategori_layanan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>SUB KATEGORI LAYANAN</h5>
        <p>{{ dataDoc.sub_kategori_layanan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA LAYANAN</h5>
        <p>{{ dataDoc.nama_layanan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>TUJUAN LAYANAN</h5>
        <p>{{ dataDoc.tujuan_layanan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>FUNGSI LAYANAN</h5>
        <p>{{ dataDoc.fungsi_layanan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>UNIT PELAKSANA</h5>
        <p>{{ dataDoc.unit_pelaksana }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>KEMENTRIAN / LEMBAGA TERKAIT</h5>
        <p>
          <b-badge
            v-for="(data, index) in dataDoc.instansi_terkait"
            :key="index"
            class="mr-25 mb-25"
          >
            {{ data }}
          </b-badge>
        </p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>TARGET LAYANAN</h5>
        <p>{{ dataDoc.target_layanan }}</p>
      </b-col>
      <b-col md="12">
        <h5>METODE LAYANAN</h5>
        <p>{{ dataDoc.metode_layanan }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BBadge,
  },
  props: {
    dataDoc: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
