<template>
  <div>
    <b-row class="pt-1 border-top-dark">
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA PETA RENCANA</h5>
        <p>{{ dataPeta.name_peta_rencana }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA SASARAN STRATEGIS</h5>
        <p>{{ dataPeta.name_sasaran_strategis }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA PROGRAM</h5>
        <p>{{ dataPeta.name_program }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA KEGIATAN</h5>
        <p>{{ dataPeta.name_kegiatan }}</p>
      </b-col>
      <b-col
        v-if="dataPeta.name_domain"
        md="6"
        class="mb-50"
      >
        <h5>NAMA ITEM PENGADAAN</h5>
        <p>{{ dataPeta.name_domain }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
  },
  props: {
    dataPeta: {
      type: Object,
      default: () => {},
    },
    dataUtama: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
