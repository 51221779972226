<template>
  <div>
    <b-row class="pt-1 border-top-dark">
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>DOMAIN APLIKASI</h5>
        <p>{{ dataAplikasi.domain_aplikasi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>AREA APLIKASI</h5>
        <p>{{ dataAplikasi.area_aplikasi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA APLIKASI</h5>
        <p>{{ dataAplikasi.nama_aplikasi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>FUNGSI APLIKASI</h5>
        <p>{{ dataAplikasi.fungsi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>SERVER APLIKASI</h5>
        <p>{{ dataAplikasi.server }}</p>
      </b-col>
      <b-col md="12">
        <h5>URAIAN APLIKASI</h5>
        <p>{{ dataAplikasi.uraian_aplikasi }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
  },
  props: {
    dataAplikasi: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
