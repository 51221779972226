<template>
  <b-card
    v-if="show === true"
    class="shadow-none"
  >
    <!-- about -->
    <h2 class="text-center mb-2">
      {{ detailProgram.nama_kegiatan }}
    </h2>
    <div class="d-flex align-items-start my-1">
      <b-avatar
        variant="light-primary"
        rounded
      >
        <feather-icon
          icon="ClipboardIcon"
          size="24"
        />
      </b-avatar>
      <div class="pl-1">
        <small>No. Tiket</small>
        <h4 class="mb-0">
          {{ detailProgram.uuid }}
        </h4>
      </div>
    </div>
    <div class="d-flex align-items-start my-1">
      <b-avatar
        variant="light-primary"
        rounded
      >
        <feather-icon
          icon="ListIcon"
          size="24"
        />
      </b-avatar>
      <div class="pl-1">
        <small>Jenis Pengadaan</small>
        <h4 class="mb-0">
          {{ detailProgram.jenis_pengadaan }}
        </h4>
      </div>
    </div>
    <div class="d-flex align-items-start my-1">
      <b-avatar
        variant="light-primary"
        rounded
      >
        <feather-icon
          icon="UserIcon"
          size="24"
        />
      </b-avatar>
      <div class="pl-1">
        <small>Penanggung Jawab</small>
        <h4 class="mb-0">
          {{ detailProgram.pic }} ({{ detailProgram.pic_number }})
        </h4>
      </div>
    </div>
    <div class="d-flex align-items-start my-1">
      <b-avatar
        variant="light-primary"
        rounded
      >
        <feather-icon
          icon="CalendarIcon"
          size="24"
        />
      </b-avatar>
      <div class="pl-1">
        <small>Tahun Anggaran</small>
        <h4 class="mb-0">
          {{ detailProgram.tahun_anggaran }}
        </h4>
      </div>
    </div>
    <div class="d-flex align-items-start my-1">
      <b-avatar
        variant="light-primary"
        rounded
      >
        <feather-icon
          icon="ShoppingBagIcon"
          size="24"
        />
      </b-avatar>
      <div class="pl-1">
        <small>Total Anggaran</small>
        <h4 class="mb-0">
          {{ Number(detailProgram.total_anggaran).toLocaleString() }}
        </h4>
      </div>
    </div>
    <div class="d-flex align-items-start my-1">
      <b-avatar
        variant="light-primary"
        rounded
      >
        <feather-icon
          icon="BookOpenIcon"
          size="24"
        />
      </b-avatar>
      <div class="pl-1">
        <small>Kode Mata Anggaran</small>
        <h4 class="mb-0">
          {{ detailProgram.kode_anggaran }}
        </h4>
      </div>
    </div>
    <div class="d-flex align-items-start my-1">
      <b-avatar
        variant="light-primary"
        rounded
      >
        <feather-icon
          icon="ShoppingCartIcon"
          size="24"
        />
      </b-avatar>
      <div class="pl-1">
        <small>Jenis Belanja</small>
        <h4 class="mb-0">
          {{ detailProgram.jenis_belanja }}
          <feather-icon
            v-if="detailProgram.prioritas_nasional === 1"
            class="text-warning"
            icon="StarIcon"
            size="16"
          />
        </h4>
      </div>
    </div>
    <div class="d-flex align-items-start my-1">
      <b-avatar
        variant="light-primary"
        rounded
      >
        <feather-icon
          icon="BriefcaseIcon"
          size="24"
        />
      </b-avatar>
      <div class="pl-1">
        <small>Sumber Dana</small>
        <h4 class="mb-0">
          {{ detailProgram.sumber_dana }}
          <feather-icon
            v-if="detailProgram.sumber_dana === 'Hibah' || detailProgram.sumber_dana === 'Pinjaman'"
            class="text-warning"
            icon="StarIcon"
            size="16"
          />
        </h4>
        <p
          v-if="detailProgram.mou_file"
          class="mt-50 mb-0"
        >
          Dokumen Penyerta<br>
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="viewFile(detailProgram.mou_file)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            />
          </b-button>
          <b-link
            :href="getDoc(detailProgram.mou_file)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            />
          </b-link>
        </p>
      </div>
    </div>
    <!-- <div class="d-flex align-items-start my-1">
      <b-avatar
        variant="light-primary"
        rounded
      >
        <feather-icon
          icon="ClockIcon"
          size="24"
        />
      </b-avatar>
      <div class="pl-1">
        <small>Perubahan Terakhir</small>
        <h4 class="mb-0">
          {{ detailProgram.last_update | formatDate }}
        </h4>
      </div>
    </div> -->

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BModal, BButton, BLink, BCardText,
} from 'bootstrap-vue'
import VuePdfApp from 'vue-pdf-app'

export default {
  components: {
    BCardText,
    BCard,
    BAvatar,
    BModal,
    BButton,
    BLink,
    VuePdfApp,
  },
  props: {
    detailProgram: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      fileDoc: '',
    }
  },
  watch: {
    detailProgram() {
      this.show = true
    },
  },
  methods: {
    viewFile(data) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
  },
}
</script>
