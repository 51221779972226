<template>
  <div
    v-if="show"
    class="list-summary-rekomendasi mt-1 border-top-secondary"
  >
    <template v-if="listRekapLanjut.length !== 0">
      <h5 class="mt-1 font-weight-normal">
        Dilanjutkan
      </h5>
      <b-list-group
        class="mb-1"
        flush
      >
        <b-list-group-item
          v-for="(data, index) in listRekapLanjut"
          :key="index"
          class="d-flex justify-content-between"
        >
          {{ data.recommendation }}
          <div
            v-if="data.author && userData.group_id < 99"
            style="width:120px"
            class="text-right"
          >
            <b-badge
              v-for="(people, keyd) in data.author"
              :key="keyd"
              v-b-tooltip.hover
              variant="light-info"
              class="ml-25"
              size="sm"
              :title="people"
            >
              {{ findInitials(people) }}
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </template>

    <template v-if="listRekapTidak.length !== 0">
      <h5 class="mt-1 font-weight-normal">
        Tidak Dilanjutkan
      </h5>
      <b-list-group
        class="mb-1"
        flush
      >
        <b-list-group-item
          v-for="(data, index) in listRekapTidak"
          :key="index"
          class="d-flex justify-content-between"
        >
          {{ data.recommendation }}
          <div
            v-if="data.author && userData.group_id < 99"
            style="width:140px"
            class="text-right"
          >
            <b-badge
              v-for="(people, keyd) in data.author"
              :key="keyd"
              v-b-tooltip.hover
              variant="light-info"
              class="ml-25"
              size="sm"
              :title="people"
            >
              {{ findInitials(people) }}
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </template>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BBadge, VBTooltip,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataUtama: {
      type: Object,
      default: () => {},
    },
    detailProgram: {
      type: Object,
      default: () => {},
    },
    stepRekomendasi: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      show: false,
      userData: getUserData(),
      mode: false,
      listRekapLanjut: [],
      listRekapTidak: [],
    }
  },
  mounted() {
    this.getTemplateReko()
  },
  methods: {
    copyReko(id) {
      navigator.clipboard.writeText(id)
    },
    findInitials(input) {
      return input.split(' ').map(item => item.toUpperCase().substring(0, 1)).join('')
    },
    getTemplateReko() {
      if (this.stepRekomendasi !== 'default') {
        if (this.stepRekomendasi === 'aplikasi') {
          this.targetUrl = 'application-data'
        }
        if (this.stepRekomendasi === 'infrastruktur') {
          this.targetUrl = 'infrastructure-data'
        }
        if (this.stepRekomendasi === 'non-teknis') {
          this.targetUrl = 'non-teknis-data'
        }
        if (this.stepRekomendasi === 'datainformasi') {
          this.targetUrl = 'information-data'
        }

        const params = {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.dataUtama.data_utama_id,
        }
        if (this.stepRekomendasi === 'aplikasi') {
          params.aplikasi_id = this.detailProgram.id
        }
        if (this.stepRekomendasi === 'infrastruktur') {
          params.infrastruktur_id = this.detailProgram.id
        }
        if (this.stepRekomendasi === 'non-teknis') {
          params.non_teknis_id = this.detailProgram.non_teknis_id
        }
        if (this.stepRekomendasi === 'datainformasi') {
          params.data_informasi_id = this.detailProgram.data_informasi_id
        }

        this.$http.get(`/recommendation/${this.targetUrl}`, {
          params,
        })
          .then(res => {
            if (res.data.status === 'success') {
              if (res.data.data.users_recommendations.length !== 0) {
                const keys = {
                  yes: [],
                  no: [],
                }
                res.data.data.users_recommendations.map(usereco => {
                  if (usereco.is_continue === 1) {
                    if (keys.yes.indexOf(usereco.rekomendasi_id) === -1) {
                      keys.yes.push(usereco.rekomendasi_id)
                      const reko = {
                        recommendation: usereco.rekomendasi_text,
                        author: [usereco.fullname],
                      }
                      this.listRekapLanjut.push(reko)
                    }
                    const lrlAuth = this.listRekapLanjut[keys.yes.indexOf(usereco.rekomendasi_id)].author

                    if (lrlAuth.indexOf(usereco.fullname) === -1) {
                      this.listRekapLanjut[keys.yes.indexOf(usereco.rekomendasi_id)].author.push(usereco.fullname)
                    }
                  } else {
                    if (keys.no.indexOf(usereco.rekomendasi_id) === -1) {
                      keys.no.push(usereco.rekomendasi_id)
                      const reko = {
                        recommendation: usereco.rekomendasi_text,
                        author: [usereco.fullname],
                      }
                      this.listRekapTidak.push(reko)
                    }
                    const lrlAuth = this.listRekapTidak[keys.no.indexOf(usereco.rekomendasi_id)].author

                    if (lrlAuth.indexOf(usereco.fullname) === -1) {
                      this.listRekapTidak[keys.no.indexOf(usereco.rekomendasi_id)].author.push(usereco.fullname)
                    }
                  }
                  return true
                })
              }
            }
          })

        this.show = true
      }
    },
  },
}
</script>
