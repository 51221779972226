<template>
  <div id="view-detail-kegiatan">
    <b-form id="formVerifikasi">
      <template v-if="utama.status_clearance">
        <b-alert
          :variant="(utama.status_clearance === 'Dilanjutkan') ? 'success' : 'warning'"
          show
        >
          <h4 class="alert-heading">
            <span>Status Clearance</span>
          </h4>
          <div class="alert-body">
            <span>{{ utama.status_clearance }}</span>
          </div>
        </b-alert>
      </template>

      <app-collapse type="margin">
        <app-collapse-item
          title="Program"
          :is-visible="true"
        >
          <template
            v-if="setStep === 'review' && program.is_verified"
          >
            <b-alert
              :variant="(program.is_verified === 1) ? 'success' : 'warning'"
              show
            >
              <h4
                v-if="program.is_verified !== 1"
                class="alert-heading"
              >
                Catatan Perbaikan
              </h4>
              <div
                v-if="program.is_verified !== 1 && program.keterangan !== ''"
                class="alert-body"
              >
                <span>{{ program.keterangan }}</span>
              </div>
              <div
                v-if="program.is_verified === 1"
                class="alert-body"
              >
                <span>Terverifikasi</span>
              </div>
            </b-alert>
          </template>

          <b-row class="pt-1 border-top-dark">
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>NAMA PROGRAM</h5>
              <p>{{ program.nama_program }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>NAMA KEGIATAN</h5>
              <p>{{ program.nama_kegiatan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>KLASIFIKASI RINCIAN OUTPUT (KRO)</h5>
              <p>{{ program.kode_model_referensi }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>RINCIAN OUTPUT (RO)</h5>
              <p>{{ program.rincian_output }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>NAMA PENANGGUNG JAWAB</h5>
              <p>{{ program.pic }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>NOMOR PENANGGUNG JAWAB</h5>
              <p>{{ program.pic_number }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>UNIT KERJA</h5>
              <p>{{ program.unit_kerja }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>INSTANSI</h5>
              <p>{{ program.instansi }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>SEKTOR PEMERINTAHAN</h5>
              <p>{{ program.sektor_pemerintahan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>URUSAN PEMERINTAHAN</h5>
              <p>{{ program.urusan_pemerintahan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>NAMA URUSAN</h5>
              <p>{{ program.nama_urusan }}</p>
            </b-col>
            <b-col md="12">
              <h5>URAIAN URUSAN</h5>
              <p>{{ program.deskripsi_urusan }}</p>
            </b-col>
          </b-row>

          <template
            v-if="setStep === 'verifikasi'"
          >
            <modul-verifikasi
              v-if="userData.group_id === 70"
              :data-utama="utama"
              :status-awal="program"
              :action-step="verifikasi"
              step-verifikasi="program"
            />
            <b-alert
              v-else
              :variant="(program.is_verified === -1) ? 'warning' : 'success'"
              show
            >
              <h4
                v-if="program.is_verified === -1"
                class="alert-heading"
              >
                Perlu Perbaikan
              </h4>
              <div
                v-if="program.is_verified === 1"
                class="alert-body"
              >
                <span>Terverifikasi</span>
              </div>
              <div
                v-else-if="program.is_verified === -1 && program.keterangan !== ''"
                class="alert-body"
              >
                <span>{{ program.keterangan }}</span>
              </div>
            </b-alert>
          </template>

          <template
            v-if="setStep === 'rekomendasi'"
          >
            <modul-rekomendasi-umum
              v-if="dataRekoSet && userData.group_id > 70 && userData.group_id < 80"
              :data-utama="utama"
              :detail-program="program"
              step-rekomendasi="program"
            />
            <summary-rekomendasi-ketua
              v-else-if="dataRekoSet && userData.group_id < 71"
              :data-utama="utama"
              :detail-program="program"
              step-rekomendasi="program"
            />
          </template>
        </app-collapse-item>

        <app-collapse-item
          id="dokumen"
          title="Dokumen"
          :is-visible="true"
        >
          <template
            v-if="setStep === 'review' && dokumen.is_verified"
          >
            <b-alert
              :variant="(dokumen.is_verified === 1) ? 'success' : 'warning'"
              show
            >
              <h4
                v-if="dokumen.is_verified !== 1"
                class="alert-heading"
              >
                Catatan Perbaikan
              </h4>
              <div
                v-if="dokumen.is_verified !== 1 && dokumen.keterangan !== ''"
                class="alert-body"
              >
                <span>{{ dokumen.keterangan }}</span>
              </div>
              <div
                v-if="dokumen.is_verified === 1"
                class="alert-body"
              >
                <span>Terverifikasi</span>
              </div>
            </b-alert>
          </template>

          <b-row class="pt-1 border-top-dark">
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>Surat Permohonan Clearance</h5>
              <p>
                <b-button
                  size="sm"
                  variant="success"
                  class="mr-25 mb-25"
                  @click="viewFile(dokumen.kak_uri)"
                >
                  View
                </b-button>
                <b-link
                  :href="getDoc(dokumen.kak_uri)"
                  class="btn btn-sm btn-info"
                  target="_blank"
                >
                  Download
                </b-link>
              </p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>Peta Rencana Program Kegiatan</h5>
              <p>
                <b-button
                  size="sm"
                  variant="success"
                  class="mr-25 mb-25"
                  @click="viewFile(dokumen.peta_uri)"
                >
                  View
                </b-button>
                <b-link
                  :href="getDoc(dokumen.peta_uri)"
                  class="btn btn-sm btn-info"
                  target="_blank"
                >
                  Download
                </b-link>
              </p>
            </b-col>
          </b-row>

          <template
            v-if="setStep === 'verifikasi'"
          >
            <modul-verifikasi
              v-if="userData.group_id === 70"
              :data-utama="utama"
              :status-awal="dokumen"
              :action-step="verifikasi"
              step-verifikasi="dokumen"
            />
            <b-alert
              v-else
              :variant="(dokumen.is_verified === -1) ? 'warning' : 'success'"
              show
            >
              <h4
                v-if="dokumen.is_verified === -1"
                class="alert-heading"
              >
                Perlu Perbaikan
              </h4>
              <div
                v-if="dokumen.is_verified === 1"
                class="alert-body"
              >
                <span>Terverifikasi</span>
              </div>
              <div
                v-else-if="dokumen.is_verified === -1 && dokumen.keterangan !== ''"
                class="alert-body"
              >
                <span>{{ dokumen.keterangan }}</span>
              </div>
            </b-alert>
          </template>

          <template
            v-else-if="setStep === 'rekomendasi'"
          >
            <modul-rekomendasi-umum
              v-if="dataRekoSet && userData.group_id > 70 && userData.group_id < 80"
              :data-utama="utama"
              :detail-program="dokumen"
              step-rekomendasi="dokumen"
            />
            <summary-rekomendasi-ketua
              v-else-if="dataRekoSet && userData.group_id < 71"
              :data-utama="utama"
              :detail-program="dokumen"
              step-rekomendasi="dokumen"
            />
          </template>
        </app-collapse-item>

        <app-collapse-item
          v-if="layanan"
          title="Layanan"
          :is-visible="true"
        >
          <template
            v-if="setStep === 'review' && layanan.is_verified"
          >
            <b-alert
              :variant="(layanan.is_verified === 1) ? 'success' : 'warning'"
              show
            >
              <h4
                v-if="layanan.is_verified !== 1"
                class="alert-heading"
              >
                Catatan Perbaikan
              </h4>
              <div
                v-if="layanan.is_verified !== 1 && layanan.keterangan !== ''"
                class="alert-body"
              >
                <span>{{ layanan.keterangan }}</span>
              </div>
              <div
                v-if="layanan.is_verified === 1"
                class="alert-body"
              >
                <span>Terverifikasi</span>
              </div>
            </b-alert>
          </template>

          <b-row
            class="pt-1 border-top-dark"
          >
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>DOMAIN LAYANAN</h5>
              <p>{{ layanan.domain_layanan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>AREA LAYANAN</h5>
              <p>{{ layanan.area_layanan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>NAMA LAYANAN</h5>
              <p>{{ layanan.nama_layanan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>TUJUAN LAYANAN</h5>
              <p>{{ layanan.tujuan_layanan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>FUNGSI LAYANAN</h5>
              <p>{{ layanan.fungsi_layanan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>UNIT PELAKSANA</h5>
              <p>{{ layanan.unit_pelaksana }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>KEMENTRIAN / LEMBAGA TERKAIT</h5>
              <p>
                <b-badge
                  v-for="(data, index) in layanan.instansi_terkait"
                  :key="index"
                  class="mr-25 mb-25"
                >
                  {{ data }}
                </b-badge>
              </p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>TARGET LAYANAN</h5>
              <p>{{ layanan.target_layanan }}</p>
            </b-col>
            <b-col md="12">
              <h5>METODE LAYANAN</h5>
              <p>{{ layanan.metode_layanan }}</p>
            </b-col>
          </b-row>

          <template
            v-if="setStep === 'verifikasi'"
          >
            <modul-verifikasi
              v-if="userData.group_id === 70"
              :data-utama="utama"
              :status-awal="layanan"
              :action-step="verifikasi"
              step-verifikasi="layanan"
            />
            <b-alert
              v-else
              :variant="(layanan.is_verified === -1) ? 'warning' : 'success'"
              show
            >
              <h4
                v-if="layanan.is_verified === -1"
                class="alert-heading"
              >
                Perlu Perbaikan
              </h4>
              <div
                v-if="layanan.is_verified === 1"
                class="alert-body"
              >
                <span>Terverifikasi</span>
              </div>
              <div
                v-else-if="layanan.is_verified === -1 && layanan.keterangan !== ''"
                class="alert-body"
              >
                <span>{{ layanan.keterangan }}</span>
              </div>
            </b-alert>
          </template>

          <template
            v-else-if="setStep === 'rekomendasi'"
          >
            <modul-rekomendasi-umum
              v-if="dataRekoSet && userData.group_id > 70 && userData.group_id < 80"
              :data-utama="utama"
              :detail-program="layanan"
              step-rekomendasi="layanan"
            />
            <summary-rekomendasi-ketua
              v-else-if="dataRekoSet && userData.group_id < 71"
              :data-utama="utama"
              :detail-program="layanan"
              step-rekomendasi="layanan"
            />
          </template>
        </app-collapse-item>

        <app-collapse-item
          v-if="dataInfo"
          title="Data dan Informasi"
          :is-visible="true"
        >
          <template
            v-if="setStep === 'review' && dataInfo.is_verified"
          >
            <b-alert
              :variant="(dataInfo.is_verified === 1) ? 'success' : 'warning'"
              show
            >
              <h4
                v-if="dataInfo.is_verified !== 1"
                class="alert-heading"
              >
                Catatan Perbaikan
              </h4>
              <div
                v-if="dataInfo.is_verified !== 1 && dataInfo.keterangan !== ''"
                class="alert-body"
              >
                <span>{{ dataInfo.keterangan }}</span>
              </div>
              <div
                v-if="dataInfo.is_verified === 1"
                class="alert-body"
              >
                <span>Terverifikasi</span>
              </div>
            </b-alert>
          </template>

          <b-row
            class="pt-1 border-top-dark"
          >
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>DATA POKOK</h5>
              <p>{{ dataInfo.data_pokok }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>DATA TEMATIK</h5>
              <p>{{ dataInfo.data_tematik }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>NAMA DATA</h5>
              <p>{{ dataInfo.nama }}</p>
            </b-col>
            <b-col md="3">
              <h5>INTEROPERABILITAS</h5>
              <p>
                <b-badge>
                  {{ dataInfo.interoperability }}
                </b-badge>
              </p>
            </b-col>
            <b-col md="3">
              <h5>SIFAT DATA</h5>
              <p>{{ dataInfo.sifat_data }}</p>
            </b-col>
            <b-col md="12">
              <h5>TUJUAN DATA</h5>
              <p>{{ dataInfo.tujuan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>VALIDITAS DATA</h5>
              <p>{{ dataInfo.valid_start | formatDate }} &dash; {{ dataInfo.valid_end | formatDate }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>PENANGGUNG JAWAB</h5>
              <p>{{ dataInfo.pic_name }}</p>
            </b-col>
            <b-col md="12">
              <h5>URAIAN DATA</h5>
              <p>{{ dataInfo.deskripsi }}</p>
            </b-col>
          </b-row>

          <template
            v-if="setStep === 'verifikasi'"
          >
            <modul-verifikasi
              v-if="userData.group_id === 70"
              :data-utama="utama"
              :status-awal="dataInfo"
              :action-step="verifikasi"
              step-verifikasi="datainformasi"
            />
            <b-alert
              v-else
              :variant="(dataInfo.is_verified === -1) ? 'warning' : 'success'"
              show
            >
              <h4
                v-if="dataInfo.is_verified === -1"
                class="alert-heading"
              >
                Perlu Perbaikan
              </h4>
              <div
                v-if="dataInfo.is_verified === 1"
                class="alert-body"
              >
                <span>Terverifikasi</span>
              </div>
              <div
                v-else-if="dataInfo.is_verified === -1 && dataInfo.keterangan !== ''"
                class="alert-body"
              >
                <span>{{ dataInfo.keterangan }}</span>
              </div>
            </b-alert>
          </template>

          <template
            v-else-if="setStep === 'rekomendasi'"
          >
            <modul-rekomendasi-umum
              v-if="dataRekoSet && userData.group_id > 70 && userData.group_id < 80"
              :data-utama="utama"
              :detail-program="dataInfo"
              step-rekomendasi="datainformasi"
            />
            <summary-rekomendasi-ketua
              v-else-if="dataRekoSet && userData.group_id < 71"
              :data-utama="utama"
              :detail-program="dataInfo"
              step-rekomendasi="datainformasi"
            />
          </template>
        </app-collapse-item>

        <app-collapse-item
          title="Aplikasi"
          :is-visible="true"
        >
          <app-collapse type="margin">
            <app-collapse-item
              v-for="(apps, index) in aplikasi"
              :key="`app-${index}`"
              :title="apps.nama_aplikasi"
              :is-visible="true"
            >
              <template
                v-if="setStep === 'review' && apps.is_verified"
              >
                <b-alert
                  :variant="(apps.is_verified === 1) ? 'success' : 'warning'"
                  show
                >
                  <h4
                    v-if="apps.is_verified !== 1"
                    class="alert-heading"
                  >
                    Catatan Perbaikan
                  </h4>
                  <div
                    v-if="apps.is_verified !== 1 && apps.keterangan !== ''"
                    class="alert-body"
                  >
                    <span>{{ apps.keterangan }}</span>
                  </div>
                  <div
                    v-if="apps.is_verified === 1"
                    class="alert-body"
                  >
                    <span>Terverifikasi</span>
                  </div>
                </b-alert>
              </template>

              <model-aplikasi
                :data-aplikasi="apps"
                @view-file="viewFile"
              />

              <template
                v-if="setStep === 'verifikasi'"
              >
                <modul-verifikasi
                  v-if="userData.group_id === 70"
                  :data-utama="utama"
                  :status-awal="apps"
                  :action-step="verifikasi"
                  step-verifikasi="aplikasi"
                />
                <b-alert
                  v-else
                  :variant="(apps.is_verified === -1) ? 'warning' : 'success'"
                  show
                >
                  <h4
                    v-if="apps.is_verified === -1"
                    class="alert-heading"
                  >
                    Perlu Perbaikan
                  </h4>
                  <div
                    v-if="apps.is_verified === 1"
                    class="alert-body"
                  >
                    <span>Terverifikasi</span>
                  </div>
                  <div
                    v-else-if="apps.is_verified === -1 && apps.keterangan !== ''"
                    class="alert-body"
                  >
                    <span>{{ apps.keterangan }}</span>
                  </div>
                </b-alert>
              </template>

              <template
                v-else-if="setStep === 'rekomendasi'"
              >
                <modul-rekomendasi-clearance
                  v-if="dataRekoSet && userData.group_id > 70 && userData.group_id < 80"
                  :data-utama="utama"
                  :detail-program="apps"
                  :component-id="apps.id"
                  step-rekomendasi="aplikasi"
                />
                <summary-rekomendasi-ketua-clearance
                  v-else-if="dataRekoSet && userData.group_id < 71"
                  :data-utama="utama"
                  :detail-program="apps"
                  :component-id="apps.id"
                  step-rekomendasi="aplikasi"
                />
              </template>
            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>

        <app-collapse-item
          title="Infrastruktur"
          :is-visible="true"
        >
          <app-collapse type="margin">
            <app-collapse-item
              v-for="(infs, index) in infra"
              :key="`inf-${index}`"
              :title="infs.nama"
              :is-visible="true"
            >
              <template
                v-if="setStep === 'review' && infs.is_verified"
              >
                <b-alert
                  :variant="(infs.is_verified === 1) ? 'success' : 'warning'"
                  show
                >
                  <h4
                    v-if="infs.is_verified !== 1"
                    class="alert-heading"
                  >
                    Catatan Perbaikan
                  </h4>
                  <div
                    v-if="infs.is_verified !== 1 && infs.keterangan !== ''"
                    class="alert-body"
                  >
                    <span>{{ infs.keterangan }}</span>
                  </div>
                  <div
                    v-if="infs.is_verified === 1"
                    class="alert-body"
                  >
                    <span>Terverifikasi</span>
                  </div>
                </b-alert>
              </template>

              <model-infra
                :data-infra="infs"
                @view-file="viewFile"
              />

              <template
                v-if="setStep === 'verifikasi'"
              >
                <modul-verifikasi
                  v-if="userData.group_id === 70"
                  :data-utama="utama"
                  :status-awal="infs"
                  :action-step="verifikasi"
                  step-verifikasi="infrastruktur"
                />
                <b-alert
                  v-else
                  :variant="(infs.is_verified === -1) ? 'warning' : 'success'"
                  show
                >
                  <h4
                    v-if="infs.is_verified === -1"
                    class="alert-heading"
                  >
                    Perlu Perbaikan
                  </h4>
                  <div
                    v-if="infs.is_verified === 1"
                    class="alert-body"
                  >
                    <span>Terverifikasi</span>
                  </div>
                  <div
                    v-else-if="infs.is_verified === -1 && infs.keterangan !== ''"
                    class="alert-body"
                  >
                    <span>{{ infs.keterangan }}</span>
                  </div>
                </b-alert>
              </template>

              <template
                v-else-if="setStep === 'rekomendasi'"
              >
                <modul-rekomendasi-clearance
                  v-if="dataRekoSet && userData.group_id > 70 && userData.group_id < 80"
                  :data-utama="utama"
                  :detail-program="infs"
                  :component-id="infs.id"
                  step-rekomendasi="infrastruktur"
                />
                <summary-rekomendasi-ketua-clearance
                  v-else-if="dataRekoSet && userData.group_id < 71"
                  :data-utama="utama"
                  :detail-program="infs"
                  :component-id="infs.id"
                  step-rekomendasi="infrastruktur"
                />
              </template>
            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>

        <app-collapse-item
          v-if="keamanan"
          title="Keamanan"
          :is-visible="true"
        >
          <template
            v-if="setStep === 'review' && keamanan.is_verified"
          >
            <b-alert
              :variant="(keamanan.is_verified === 1) ? 'success' : 'warning'"
              show
            >
              <h4
                v-if="keamanan.is_verified !== 1"
                class="alert-heading"
              >
                Catatan Perbaikan
              </h4>
              <div
                v-if="keamanan.is_verified !== 1 && keamanan.keterangan !== ''"
                class="alert-body"
              >
                <span>{{ keamanan.keterangan }}</span>
              </div>
              <div
                v-if="keamanan.is_verified === 1"
                class="alert-body"
              >
                <span>Terverifikasi</span>
              </div>
            </b-alert>
          </template>

          <b-row
            class="pt-1 border-top-dark"
          >
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>JENIS STANDAR KEAMANAN</h5>
              <p>{{ keamanan.standar_keamanan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>KETERANGAN NAMA STANDAR KEAMANAN</h5>
              <p>{{ (keamanan.nama_standar !== '') ? keamanan.nama_standar : '-' }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>HASIL AUDIT KEAMANAN</h5>
              <p>{{ (keamanan.hasil_audit !== '') ? keamanan.hasil_audit : '-' }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>TANGGAL PELAKSANAN AUDIT</h5>
              <p>{{ (keamanan.tanggal_audit !== '') ? keamanan.tanggal_audit : '-' }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>PENERAPAN KEAMANAN</h5>
              <p>{{ (keamanan.penerapan_keamanan !== '') ? keamanan.penerapan_keamanan : '-' }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>PENGUJI KELAIKAN KEAMANAN</h5>
              <p>{{ (keamanan.kelaikan_keamanan !== '') ? keamanan.kelaikan_keamanan : '-' }}</p>
            </b-col>
          </b-row>

          <template
            v-if="setStep === 'verifikasi'"
          >
            <modul-verifikasi
              v-if="userData.group_id === 70"
              :data-utama="utama"
              :status-awal="keamanan"
              :action-step="verifikasi"
              step-verifikasi="keamanan"
            />
            <b-alert
              v-else
              :variant="(keamanan.is_verified === -1) ? 'warning' : 'success'"
              show
            >
              <h4
                v-if="keamanan.is_verified === -1"
                class="alert-heading"
              >
                Perlu Perbaikan
              </h4>
              <div
                v-if="keamanan.is_verified === 1"
                class="alert-body"
              >
                <span>Terverifikasi</span>
              </div>
              <div
                v-else-if="keamanan.is_verified === -1 && keamanan.keterangan !== ''"
                class="alert-body"
              >
                <span>{{ keamanan.keterangan }}</span>
              </div>
            </b-alert>
          </template>

          <template
            v-else-if="setStep === 'rekomendasi'"
          >
            <modul-rekomendasi-umum
              v-if="dataRekoSet && userData.group_id > 70 && userData.group_id < 80"
              :data-utama="utama"
              :detail-program="keamanan"
              step-rekomendasi="keamanan"
            />
            <summary-rekomendasi-ketua
              v-else-if="dataRekoSet && userData.group_id < 71"
              :data-utama="utama"
              :detail-program="keamanan"
              step-rekomendasi="keamanan"
            />
          </template>
        </app-collapse-item>
      </app-collapse>
    </b-form>

    <template v-if="errorStat">
      <b-alert
        variant="danger"
        class="mt-2"
        show
      >
        <h4 class="alert-heading">
          Error Found
        </h4>
        <div class="alert-body">
          {{ errorMsg }}
        </div>
      </b-alert>
    </template>

    <template
      v-if="(userData.group_id > 70 && userData.group_id < 81) && setStep === 'rekomendasi' && program.status !== 'Draft'"
    >
      <b-card class="mt-2">
        <b-card-text
          cols="12"
          class="text-center"
        >
          <h4>Pemberian Rekomendasi Selesai?</h4>
          <p v-if="!submitted">
            <b-button
              :to="{name: 'rekomendasi-awal' }"
              variant="outline-secondary"
              class="mx-25"
            >
              Belum
            </b-button>
            <b-button
              v-b-modal.modal-warning4
              variant="outline-success"
              class="mx-25"
            >
              Ya, Selesai
            </b-button>
          </p>
          <p v-else>
            <span class="text-success">Data Berhasil Disimpan</span>
          </p>
        </b-card-text>
      </b-card>

      <b-modal
        id="modal-warning4"
        ref="modal-warning4"
        cancel-variant="outline-secondary"
        ok-variant="success"
        cancel-title="Batal"
        ok-title="Ya, Selesai!"
        modal-class="modal-warning"
        centered
        title="Attention!"
        @ok="simpanDataRekomendasi()"
      >
        <b-card-text>
          Dengan ini menyatakan bahwa proses pemberian rekomendasi telah sepenuhnya selesai?
        </b-card-text>
      </b-modal>
    </template>

    <template
      v-else-if="userData.group_id === 70 && setStep === 'verifikasi' && program.status !== 'DRAFT'"
    >
      <b-card class="mt-2">
        <b-card-text
          cols="12"
          class="text-center"
        >
          <h4>Verifikasi Teknis Selesai?</h4>
          <p v-if="!submitted">
            <b-button
              :to="{ name: 'verifikasi-teknis' }"
              variant="outline-secondary"
              class="mx-25"
            >
              Belum
            </b-button>
            <b-button
              v-b-modal.modal-warning3
              variant="outline-success"
              class="mx-25"
            >
              Ya, Selesai
            </b-button>
          </p>
          <p v-else>
            <span class="text-success">Data Berhasil Disimpan</span>
          </p>
        </b-card-text>
      </b-card>

      <b-modal
        id="modal-warning3"
        ref="modal-warning3"
        cancel-variant="outline-secondary"
        ok-variant="success"
        cancel-title="Batal"
        ok-title="Ya, Selesai!"
        modal-class="modal-warning"
        centered
        title="Attention!"
        @ok="simpanDataVerifikasi()"
      >
        <b-card-text>
          Dengan ini menyatakan bahwa proses verifikasi teknis telah sepenuhnya selesai?
        </b-card-text>
      </b-modal>
    </template>

    <template
      v-else-if="(userData.group_id === 100 || userData.group_id === 101) && (program.status === 'Draft' || program.status === 'Perlu Perbaikan')"
    >
      <b-card class="mt-2">
        <b-card-text
          cols="12"
          class="text-center"
        >
          <p v-if="!submitted">
            <b-button
              v-if="userData.group_id === 100"
              variant="outline-warning"
              class="mr-50"
              :to="{ name: 'pengajuan-surat-ubah', params: { id: utama.clearance_id } }"
            >
              Ubah Surat
            </b-button>
            <b-button
              variant="outline-warning"
              class="mr-50"
              :to="{ name: 'kegiatan-ubah', params: { id: this.$route.params.id } }"
            >
              Ubah / Lengkapi Data kegiatan
            </b-button>
            <b-button
              v-b-modal.modal-warning1
              variant="outline-success"
            >
              Simpan Sebagai Data Lengkap
            </b-button>
          </p>
          <p v-else>
            <span class="text-success">Data Berhasil Disimpan</span>
          </p>
        </b-card-text>
      </b-card>
    </template>

    <template
      v-else-if="userData.group_id === 100 && program.status === 'Draft Lengkap'"
    >
      <b-card class="mt-2">
        <b-card-text
          cols="12"
          class="text-center"
        >
          <p v-if="!submitted">
            <b-button
              variant="outline-warning"
              class="mr-50"
              :to="{ name: 'kegiatan-ubah', params: { id: this.$route.params.id } }"
            >
              Ubah / Lengkapi Data kegiatan
            </b-button>
            <b-button
              v-b-modal.modal-warning2
              variant="outline-success"
            >
              Submit Untuk Clearance
            </b-button>
          </p>
          <p v-else>
            <span class="text-success">Data Berhasil Disimpan</span>
          </p>
        </b-card-text>
      </b-card>
    </template>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning1"
      ref="modal-warning1"
      cancel-variant="outline-secondary"
      ok-variant="success"
      cancel-title="Batal"
      ok-title="Ya, Simpan!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @ok="simpanLengkap()"
    >
      <b-card-text>
        Menyatakan Bahwa Input Kegiatan Telah Lengkap?
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning2"
      ref="modal-warning2"
      cancel-variant="outline-secondary"
      ok-variant="success"
      cancel-title="Batal"
      ok-title="Ya, Lanjutkan!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @ok="ajukanClearance()"
    >
      <b-card-text>
        Ajukan Kegiatan Untuk Clearance?
      </b-card-text>
    </b-modal>
  </div>

</template>

<script>
import {
  BButton, BRow, BCol, BModal, BCardText, BCard, BLink, BForm, BBadge, BAlert,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import VuePdfApp from 'vue-pdf-app'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import ModelAplikasi from './KegiatanAplikasi.vue'
import ModelInfra from './KegiatanInfrastruktur.vue'

import ModulVerifikasi from '../../verifikasi-teknis/ModulVerifikasi.vue'
import ModulRekomendasiUmum from '../../rekomendasi/ModulRekomendasiUmum.vue'
import ModulRekomendasiClearance from '../../rekomendasi/ModulRekomendasiClearance.vue'

import SummaryRekomendasiKetua from '../../rekomendasi/SummaryRekomendasiKetua.vue'
import SummaryRekomendasiKetuaClearance from '../../rekomendasi/SummaryRekomendasiKetuaClearance.vue'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BAlert,
    BBadge,
    BForm,
    BLink,
    BCardText,
    BModal,
    BButton,
    BCard,
    BCol,
    BRow,
    AppCollapse,
    AppCollapseItem,
    ModelAplikasi,
    ModelInfra,
    ModulVerifikasi,
    ModulRekomendasiUmum,
    ModulRekomendasiClearance,
    SummaryRekomendasiKetua,
    SummaryRekomendasiKetuaClearance,
    VuePdfApp,
  },
  props: {
    setStep: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userData: getUserData(),
      mode: true,
      verifikasi: 0,
      rekomendasi: 0,
      submitted: false,
      dataRekoSet: false,
      fileDoc: '',
      errorStat: false,
      errorMsg: '',
      dataProgram: {},
      utama: {},
      program: {},
      dokumen: {},
      layanan: {},
      dataInfo: {},
      aplikasi: {},
      infra: {},
      keamanan: {},
    }
  },
  mounted() {
    this.$http.get('/clearance/preview', {
      params: {
        token: localStorage.getItem('userToken'),
        data_utama_id: this.$route.params.id,
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.dataProgram = res.data.data

          if (this.dataProgram.data_utama) {
            this.utama = this.dataProgram.data_utama
          }
          if (this.dataProgram.program) {
            this.program = this.dataProgram.program
          }
          if (this.dataProgram.dokumen) {
            this.dokumen = this.dataProgram.dokumen
          }
          if (this.dataProgram.layanan) {
            this.layanan = this.dataProgram.layanan
          }
          if (this.dataProgram.data_info) {
            this.dataInfo = this.dataProgram.data_info
          }
          if (this.dataProgram.aplikasi) {
            this.aplikasi = this.dataProgram.aplikasi
          }
          if (this.dataProgram.infra) {
            this.infra = this.dataProgram.infra
          }
          if (this.dataProgram.keamanan) {
            this.keamanan = this.dataProgram.keamanan
          }
          this.dataRekoSet = true
        }
      })
  },
  methods: {
    viewFile(data) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    simpanLengkap() {
      const formData = {
        clearance_id: this.utama.clearance_id,
        data_utama_id: this.utama.data_utama_id,
      }
      this.$http.post('/clearance/confirm-complete', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data) {
            this.submitted = true

            setTimeout(() => {
              this.$router.replace('/pengajuan')
            }, 1200)
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    ajukanClearance() {
      const formData = {
        clearance_id: this.utama.clearance_id,
        data_utama_id: this.utama.data_utama_id,
      }
      this.$http.post('/clearance/confirm-submit', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data) {
            this.submitted = true

            setTimeout(() => {
              this.$router.replace('/pengajuan')
            }, 1200)
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    simpanDataVerifikasi() {
      // this.verifikasi += 1
      const formData = {
        clearance_id: this.utama.clearance_id,
        data_utama_id: this.utama.data_utama_id,
      }
      this.$http.post('/verification/submit', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.submitted = true
            this.errorStat = false

            setTimeout(() => {
              this.$router.replace('/verifikasi-teknis')
            }, 1200)
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    simpanDataRekomendasi() {
      this.rekomendasi += 1
      const formData = {
        clearance_id: this.utama.clearance_id,
        data_utama_id: this.utama.data_utama_id,
      }
      this.$http.post('/recommendation/submit', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.submitted = true

            setTimeout(() => {
              this.$router.replace('/rekomendasi-awal')
            }, 1200)
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>
